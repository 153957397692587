import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  CxTableFilterTypeEnum,
  CxTableLabelTypeEnum,
  CxTableRowTypeEnum,
} from '@bbraun/cortex/table-utilities';
import { DateRange } from '@angular/material/datepicker';
import { firstValueFrom, forkJoin, Subject, takeUntil } from 'rxjs';
import { MatSortable } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ColumnDefEnum, ColumnFilterEnum } from './column-def.enum';
import { TranslateService } from '@ngx-translate/core';
import { OrderStateEnum } from '../../../shared/model/order-state.enum';
import { LocalStorageKeysEnum } from '../../../shared/model/local-storage-keys.enum';
import { OrderView } from '../../order-view.model';
import { OrderHttpService } from '../../order-detail/order-http.service';
import { Router } from '@angular/router';
import { PageRequest } from '../../../shared/model/page-request.model';
import { OrderFilter } from './order-filter';
import { UserHttpService } from '../../../shared/service/user-http.service';
import {
  AuthorizedUser,
  hasAnyOfThoseRoles,
  UserRole,
} from '../../../shared/model/user.model';
import { CxDialogService } from '@bbraun/cortex/dialog';

@Component({
  selector: 'hpm-order-table',
  templateUrl: './order-table.component.html',
  styleUrl: './order-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTableComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();

  defaultOrderTableColumns = [
    ColumnDefEnum.LAST_CHANGE_DATE,
    ColumnDefEnum.CUSTOMER,
    ColumnDefEnum.SPECIALTY_FIELD,
    ColumnDefEnum.ASSIGNED_TO,
    ColumnDefEnum.STATUS,
    ColumnDefEnum.PRIORITY,
    ColumnDefEnum.CUSTOMER_TRANSACTION_NUMBER,
    ColumnDefEnum.MENU,
  ];
  readonly defaultPageSize: number = 10;
  totalElementCount = 0;

  tableSettings: {
    pageSize: number;
    pageNumber: number;
    sort: { id: string; start: 'asc' | 'desc' | '' };
    filter: OrderFilter;
  } = {
    pageSize: this.defaultPageSize,
    pageNumber: 0,
    sort: { id: 'lastChangeDate', start: 'desc' },
    filter: new OrderFilter(),
  };

  shownOrders: OrderView[] = [];
  tableLabelType = CxTableLabelTypeEnum;
  rowType = CxTableRowTypeEnum;
  tableFilterType = CxTableFilterTypeEnum;
  columnDef = ColumnDefEnum;
  columnFilterEnum = ColumnFilterEnum;
  tableLoading = false;
  userCanSeeCustomerTransactionNumber = false;
  @ViewChild('orderUpdateDialog') templateRef: TemplateRef<never> | undefined;
  currentUser: AuthorizedUser | null = null;

  translateStatusLabelToStatusEnum = new Map<string, string>();

  filterValueMap: OrderFilter | undefined;
  selectedLastChangedDateRange: DateRange<Date> | DateRange<null> =
    new DateRange<Date>(null, null);
  selectedCreatedOnDateRange: DateRange<Date> | DateRange<null> =
    new DateRange<Date>(null, null);
  autocompleteOptions: string[] = [];

  constructor(
    private translateService: TranslateService,
    private orderHttpService: OrderHttpService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private userService: UserHttpService,
    private dialogService: CxDialogService,
  ) {}

  ngOnInit(): void {
    this.fillOrderStatusTranslateMap();
    this.loadFilterValues();
    this.loadConfigFromStorage();
    this.loadOrders();
    this.loadAutocompleteOptions();
    this.userService.loadCurrentUser();
    this.prepareFormForAuthorization();
    this.getCurrentUser();
  }

  getCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      });
    this.cdr.detectChanges();
  }

  private loadAutocompleteOptions(): void {
    this.orderHttpService
      .getAutocompleteOptions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((autocompleteOptions) => {
        this.autocompleteOptions = autocompleteOptions;
      });
  }

  private loadConfigFromStorage(): void {
    // Load columns order
    const storedColumnsOrder = localStorage.getItem(
      LocalStorageKeysEnum.ORDER_OVERVIEW_TABLE_COLUMN_ORDER,
    );

    if (storedColumnsOrder) {
      const parsedColumnsOrder = JSON.parse(storedColumnsOrder);

      if (!parsedColumnsOrder.includes(ColumnDefEnum.MENU)) {
        parsedColumnsOrder.push(ColumnDefEnum.MENU);

        localStorage.setItem(
          LocalStorageKeysEnum.ORDER_OVERVIEW_TABLE_COLUMN_ORDER,
          JSON.stringify(parsedColumnsOrder),
        );
      }

      this.defaultOrderTableColumns = parsedColumnsOrder;
    }
  }

  private prepareFormForAuthorization(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.userCanSeeCustomerTransactionNumber = hasAnyOfThoseRoles(
          currentUser,
          [UserRole.OFFICE_SERVICE, UserRole.FIELD_SERVICE, UserRole.ADMIN],
        );
      });
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  createDateRangeFromDate(
    dateRange: DateRange<Date | null>,
    date: Date,
  ): DateRange<Date> | DateRange<null> {
    if (dateRange?.start && date > dateRange.start && !dateRange.end) {
      dateRange = new DateRange(dateRange.start, date);
    } else {
      dateRange = new DateRange(date, null);
    }
    return dateRange;
  }

  onSelectedCalenderDate(column: string, date: Date | null): void {
    if (!date) {
      return;
    }
    const dateRange: string[] = [];
    if (column === this.columnFilterEnum.LAST_CHANGE_DATE) {
      this.selectedLastChangedDateRange = this.createDateRangeFromDate(
        this.selectedLastChangedDateRange,
        date,
      );
      if (this.selectedLastChangedDateRange.start) {
        dateRange.push(
          this.toLocalIsoDate(this.selectedLastChangedDateRange.start),
        );
      }
      if (this.selectedLastChangedDateRange.end) {
        dateRange.push(
          this.toLocalIsoDate(this.selectedLastChangedDateRange.end),
        );
      }
    }
    if (column === this.columnFilterEnum.CREATED_ON) {
      this.selectedCreatedOnDateRange = this.createDateRangeFromDate(
        this.selectedCreatedOnDateRange,
        date,
      );
      if (this.selectedCreatedOnDateRange.start) {
        dateRange.push(
          this.toLocalIsoDate(this.selectedCreatedOnDateRange.start),
        );
      }
      if (this.selectedCreatedOnDateRange.end) {
        dateRange.push(
          this.toLocalIsoDate(this.selectedCreatedOnDateRange.end),
        );
      }
    }
    this.tableSettings.filter[column] = dateRange;
    this.tableSettings.pageNumber = 0;
    this.loadOrders();
  }

  private toLocalIsoDate(date: Date): string {
    // toISOString returns UTC, but we need LocalTime
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    ).toISOString();
  }

  addSearchFilter(searchvalue: string[]): void {
    this.onFilterSelectionChange('search.fields', this.getSearchFields());
    this.onFilterSelectionChange('search.terms', searchvalue);
  }

  private getSearchFields(): string[] {
    const result = [];
    result.push('customer');
    result.push('address');
    if (this.userCanSeeCustomerTransactionNumber) {
      result.push('customerTransactionNumber');
    }
    return result;
  }

  onFilterSelectionChange(column: string, selectedValues: Array<string>): void {
    const oldFilterValue = this.tableSettings.filter[column];
    if (this.filterDidChange(oldFilterValue, selectedValues)) {
      // table filters emit change on initialization. prevent loading orders on init
      this.tableSettings.filter[column] = selectedValues;
      this.tableSettings.pageNumber = 0;
      this.loadOrders();
    }
  }

  private filterDidChange(
    oldFilterValue: string[],
    selectedValues: Array<string>,
  ): boolean {
    return !(oldFilterValue?.length === 0 && selectedValues?.length === 0);
  }

  onSortingDirectionChanged(sortData: MatSortable): void {
    this.tableSettings.pageNumber = 0;
    this.tableSettings.sort = {
      start: sortData.start,
      id: sortData.id,
    };
    this.loadOrders();
  }

  private loadOrders(): void {
    this.tableLoading = true;
    const pageRequest = new PageRequest({
      page: this.tableSettings.pageNumber,
      size: this.tableSettings.pageSize,
      filter: this.orderFilterToMap(),
    });
    if (this.tableSettings.sort && this.tableSettings.sort.start !== '') {
      pageRequest.sort = this.tableSettings.sort;
    }
    this.orderHttpService
      .getAll(pageRequest)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((pagedAllOrders) => {
        this.shownOrders = pagedAllOrders.content;
        this.totalElementCount = pagedAllOrders.totalElements;

        this.tableLoading = false;
        this.cdr.detectChanges();
      });
  }

  onPaginatorChange(changeData: PageEvent): void {
    this.tableSettings.pageNumber = changeData.pageIndex;
    this.tableSettings.pageSize = changeData.pageSize;
    this.loadOrders();
  }

  saveVisibleColumnsToStorage(visibleColumns: Array<string>): void {
    localStorage.setItem(
      LocalStorageKeysEnum.ORDER_OVERVIEW_TABLE_COLUMN_ORDER,
      JSON.stringify(visibleColumns),
    );
  }

  private fillOrderStatusTranslateMap(): void {
    // Translate enums to label values. Filter needs translated values
    Object.keys(OrderStateEnum).forEach((statusKey) => {
      this.translateService
        .get('ORDER_OVERVIEW.ORDER_STATUS.' + statusKey.toString())
        .subscribe((translation: string) => {
          this.translateStatusLabelToStatusEnum.set(
            translation,
            OrderStateEnum[statusKey as keyof typeof OrderStateEnum],
          );
        });
    });
  }

  private loadFilterValues(): void {
    this.orderHttpService
      .getFilterValues()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((filterValueMap) => {
        this.filterValueMap = filterValueMap;
        this.filterValueMap.status = this.filterValueMap.status.map((state) => {
          let mappedValue;
          this.translateStatusLabelToStatusEnum.forEach((enumKey, label) => {
            if (enumKey === state) {
              mappedValue = label;
            }
          });
          return mappedValue ? mappedValue : state;
        });
      });
  }

  private orderFilterToMap(): Map<string, string[]> {
    const filter: Map<string, string[]> = new Map<string, string[]>();
    const filterKeys = Object.keys(this.tableSettings.filter);
    for (const filterKey of filterKeys) {
      if (filterKey === 'status') {
        const statusFilterValues = this.tableSettings.filter[filterKey];
        if (statusFilterValues.length > 0) {
          const strings: string[] = [...statusFilterValues].map((statusValue) =>
            this.translateStatusLabelToStatusEnum.get(statusValue),
          ) as string[];
          filter.set(filterKey, strings);
        }
      } else {
        filter.set(filterKey, this.tableSettings.filter[filterKey]);
      }
    }
    return filter;
  }

  navigateToDetailPage($event: {
    rowIndex: number;
    mouseEvent: MouseEvent;
  }): void {
    const orderId = this.shownOrders[$event.rowIndex].orderId;
    this.router.navigateByUrl(`/order/${orderId}`);
  }

  checkPermissionToDeleteOrders(order: OrderView): boolean {
    const orderStatus = order.status;
    const orderCreator = order.createdBy;
    const completedOrder = OrderStateEnum.COMPLETED;
    const releasedOrder = OrderStateEnum.RELEASED;
    const editingOrder = OrderStateEnum.IN_CAPTURE;
    if (
      hasAnyOfThoseRoles(this.currentUser, [UserRole.ADMIN]) &&
      orderStatus !== completedOrder
    ) {
      return true;
    } else if (
      hasAnyOfThoseRoles(this.currentUser, [UserRole.OFFICE_SERVICE]) &&
      orderStatus !== completedOrder &&
      orderStatus !== releasedOrder
    ) {
      return true;
    } else if (orderCreator === this.currentUser?.username) {
      if (orderStatus === editingOrder) {
        return hasAnyOfThoseRoles(this.currentUser, [
          UserRole.FIELD_SERVICE,
          UserRole.RETAIL_PARTNER,
        ]);
      } else {
        return false;
      }
    }
    return false;
  }

  deleteOrder(order: OrderView): void {
    this.orderHttpService
      .deleteOrder(order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadOrders();
      });
  }

  confirmDeleteOrder(order: OrderView): void {
    const userCanDeleteOrders = this.checkPermissionToDeleteOrders(order);
    if (userCanDeleteOrders) {
      this.openDialog(order);
    }
  }

  private async openDialog(order: OrderView): Promise<void> {
    const [title, cancel, confirm] = await firstValueFrom(
      forkJoin([
        this.translateService.get('ORDER_OVERVIEW.DIALOG.HEADLINE'),
        this.translateService.get('ORDER_OVERVIEW.DIALOG.CANCEL'),
        this.translateService.get('ORDER_OVERVIEW.DIALOG.CONFIRM'),
      ]),
    );
    this.dialogService
      .openDeleteDialog(title, '', confirm, cancel)
      .subscribe((confirmation) => {
        if (confirmation) {
          this.deleteOrder(order);
        }
      });
  }
}
